import React, { useEffect, useState } from 'react'
import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import {getTotal} from "./dashboardData"
// import { mockPieData as data } from "../data/mockData";

const PieChart = ({type}) => {
  // console.log('HOLA:',type)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState([])

  const [totalVotesVisa, setTotalVotesVisa] = useState()
  const [totalVotesMastercard, setTotalVotesMastercard] = useState()
  const [totalVotesOther, setTotalVotesOther] = useState()

  const [totalVotesExecuted, setTotalVotesExecuted] = useState()
  const [totalVotesDeclined, setTotalVotesDeclined] = useState()
  const [totalVotesFailed, setTotalVotesFailed] = useState()

  useEffect(()=>{
    getTotal('total_number_of_payments_visa', setTotalVotesVisa)
    getTotal('total_number_of_payments_mastercard', setTotalVotesMastercard)
    getTotal('total_number_of_payments_other', setTotalVotesOther)
  
    getTotal('total_number_of_payments_executed', setTotalVotesExecuted)
    getTotal('total_number_of_payments_declined', setTotalVotesDeclined)
    getTotal('total_number_of_payments_failed', setTotalVotesFailed)
  
  }, [])

  let data_payment_type = [
    {
      id: "Visa",
      label: "Visa",
      value: totalVotesVisa,
      color: "hsl(104, 70%, 50%)",
    },
    {
      id: "MC",
      label: "Mastercard",
      value:  totalVotesMastercard,
      color: "hsl(162, 70%, 50%)",
    },
    {
      id: "Other",
      label: "Other",
      value: totalVotesOther,
      color: "hsl(291, 70%, 50%)",
    },
  ];

  let data_payment_status = [
    {
      id: "Exec",
      label: "Executed",
      value: totalVotesExecuted,
      color: "hsl(104, 70%, 50%)",
    },
    {
      id: "Decl",
      label: "Declined",
      value:  totalVotesDeclined,
      color: "hsl(162, 70%, 50%)",
    },
    {
      id: "Fail",
      label: "Failed",
      value: totalVotesFailed,
      color: "hsl(291, 70%, 50%)",
    },
  ];

   useEffect(() => {
    if (data !== undefined) {
      if (type === "PAYMENT_TYPE"){
        if (
          totalVotesVisa === undefined ||
          totalVotesMastercard === undefined ||
          totalVotesOther === undefined 
        ){
          // console.log("No hago na");
        }else{
          setData(data_payment_type)
  
        }
      }else if(type === "PAYMENT_STATUS"){
        if (
          totalVotesExecuted === undefined ||
          totalVotesDeclined === undefined ||
          totalVotesFailed === undefined 
        ){
          // console.log("No hago na");
        }else{
          setData(data_payment_status)
  
        }
      }
    }
	 }, [totalVotesVisa,
    totalVotesMastercard,
    totalVotesOther,
    totalVotesExecuted,
    totalVotesDeclined,
    totalVotesFailed])

  return (
    <ResponsivePie
      data={data}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
      }}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor={colors.grey[100]}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      enableArcLabels={false}
      arcLabelsRadiusOffset={0.4}
      arcLabelsSkipAngle={7}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      legends={[
        {
          anchor: "bottom",
          direction: "column",
          justify: false,
          translateX: 0,
          translateY: 70,
          itemsSpacing: 5,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: "#999",
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );
};

export default PieChart;
