import { Box, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import Header from "../../components/Header";
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

import en from '../dashboard/dashboard-i18n/en'
import es from '../dashboard/dashboard-i18n/es'

i18next.addResourceBundle('en', 'dashboard', en)
i18next.addResourceBundle('es', 'dashboard', es)

const Topbar = () => {
  const { t } = useTranslation('dashboard')

  // const [openLanguageMenu, setOpenLanguageMenu] = useState(null)
  // const [userMenu, setUserMenu] = useState(null)

  return (
    // <Box display="flex" justifyContent="space-between" align-items="center" p={2}>
    <Box className="flex flex-col" justifyContent="space-between" align-items="center" p={2}>
      {/* SEARCH BAR */}
      <div
        // className="flex flex-row w-full justify-center"
        style={{display:'flex', flexDirection:'row', justifyContent:'center'}}
        // backgroundColor={colors.primary[400]}
        // borderRadius="3px"
        
      >
        <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../MissWorld/missLogo.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
      </div>
      <Header title={t('DASHBOARD')} subtitle="Welcome to your dashboard" />

      {/* ICONS */}
      <Box display="flex">
        {/* <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton> */}
        {/* <IconButton>
          <MenuItem
              onClick={event => {
                setOpenLanguageMenu(event.currentTarget)
              }}
              role='button'
            >
              <ListItemIcon className='min-w-40'>
                <TranslateIcon />
              </ListItemIcon>
              <ListItemText primary={'LANGUAGE'} />
          </MenuItem>

          <LanguageSwitcher menu={openLanguageMenu} setMenu={setOpenLanguageMenu} setUserMenu={setUserMenu} />
        </IconButton> */}
        {/* <IconButton>
          <SettingsOutlinedIcon />
        </IconButton> */}
        {/* <IconButton>
          <PersonOutlinedIcon />
        </IconButton> */}
      </Box>
    </Box>
  );
};

export default Topbar;
