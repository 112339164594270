import React, { useEffect, useState } from 'react'
import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../theme";
import {getListOfData} from "./dashboardData"


const BarChart = ({ isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState([])
  const [missList, setMissList] = useState([]);

  useEffect(()=>{
    getListOfData('participants', setMissList)
  }, [])

  useEffect(() => {
      let formatted = missList.map((d) => ({"miss_name" : d.key,
                                       "1 votes" : d['1votes']==undefined? 0 : d['1votes'],
                                       "1votesColor" : "hsl(296, 70%, 50%)",
                                       "2 votes" : d['2votes']==undefined? 0 : d['2votes']*2,
                                       "2votesColor" : "hsl(97, 70%, 50%)",
                                       "3 votes" : d['3votes']==undefined? 0 : d['3votes']*3, 
                                       "3votesColor" : "hsl(340, 70%, 50%)" }))
      setData(formatted);
      // console.log('HOLA:', data)
	 }, [missList])

  return (
    <ResponsiveBar
      data={data}
      // layout={window.innerWidth > 600 ? "horizontal" : 'vertical'}
      theme={{
        // added
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
      }}
      keys={["1 votes", "2 votes", "3 votes"]}
      indexBy="miss_name"
      // margin={{ top: 50, right: 130, bottom: 50, left: window.innerWidth > 600 ? 130 : 60 }}
      margin={{ top: 70, right: 10, bottom: 130, left: 30 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "nivo" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", "1.6"]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -70,
        legend: isDashboard ? undefined : "country", // changed
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "food", // changed
        legendPosition: "middle",
        legendOffset: -40,
      }}
      enableLabel={true}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "top",
          direction: "row",
          justify: false,
          // translateX: 120,
          translateY: -30,
          itemsSpacing: -30,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      barAriaLabel={function (e) {
        return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
      }}
    />
  );
};

export default BarChart;
