import { Routes, Route, useNavigate } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import LoginPage from "./pages/login/LoginPage";
import { useEffect } from "react";

function App() {
  const [theme, colorMode] = useMode();
  const navigate = useNavigate();

  useEffect(() => {
    const pathName = window.location.pathname;
    if (pathName !== "/login" && pathName !== "/home") navigate("/login");
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content">
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/home" element={<Dashboard />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
