import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  IconButton,
  Typography,
  InputAdornment,
  Card,
  CardContent,
  Divider,
  TextField,
} from "@mui/material";
import "./login.css";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Loading } from "notiflix";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useNavigate } from "react-router-dom";
import { red } from "@mui/material/colors";
import { isMobile } from "react-device-detect";

function LoginPage() {
  const inputRef = useRef();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [guid, setGuid] = useState();
  const [token, setToken] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [error2fa, setError2fa] = useState(false);

  const [value2Fa, setValue2Fa] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    // inputRef.current.focus();
  };

  const API_URL = process.env.REACT_APP_URL;

  function isFormValid() {
    return email.length > 0 && password.length > 0;
  }
  function isTokenValid() {
    return value2Fa.length === 6;
  }

  const login = () => {
    Loading.hourglass();

    axios
      .post(`${API_URL}login`, {
        email,
        password,
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("login 200");
          navigate("/home");
        }
      })
      .catch((error) => {
        console.error("Error in saveUserInfo: ", error);
      })
      .finally(() => Loading.remove());
  };

  const login2FA = () => {
    Loading.hourglass();

    axios
      .post(`${API_URL}/login2fa`, {
        email,
        password,
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("login 2FA 200");
        }
      })
      .catch((error) => {
        console.error("Error in saveUserInfo: ", error);
      })
      .finally(() => Loading.remove());
  };

  return (
    <div className="loginPage">
      <div className="loginBannerDiv">
        <img className="loginBanner" src="/assets/login/loginBanner.png" />
        <img
          className={
            isMobile ? "loginTransparentLogoMobile" : "loginTransparentLogo"
          }
          src="/assets/logo/transparentLogo.png"
        />
        <p className={isMobile ? "loginHastagMobile" : "loginHastag"}>
          #MissWorld2023
        </p>
      </div>

      <div className="loginBody">
        <Typography className={isMobile ? "loginTextMobile" : "loginText"}>
          Analyse your business data in one place,
          <br />
          so you can make smarter decisions
        </Typography>
        <div className={isMobile ? "loginBoxMobile" : "loginBox"}>
          <Typography
            align="left"
            style={{
              display: isMobile ? "none" : "",
              fontSize: "20px",
              color: "black",
              marginBottom: "1rem",
            }}
          >
            Login
          </Typography>

          <TextField
            label="Email"
            autoFocus
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
            required
            fullWidth
            InputProps={{
              readOnly: !!token,
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                console.log("enter press here! ");
                login();
              }
            }}
            style={{ marginBottom: "1rem" }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& > fieldset": {
                  border: "2px solid rgb(204,204,204)",
                  borderRadius: "8px",
                },
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                "& > fieldset": {
                  border: "2px solid rgb(30, 30, 30)",
                  borderRadius: "8px",
                },
              },
              "& .MuiInputBase-input:-webkit-autofill": {
                boxShadow: "0 0 0 100px #ddf1ff inset",
                MozBoxShadow: "0 0 0 100px #ddf1ff inset",
                WebkitBoxShadow: "0 0 0 100px #ddf1ff inset",
                WebkitTextFillColor: "black",
              },
            }}
          />

          <TextField
            className="passwordField"
            label="Password"
            inputRef={inputRef}
            type={showPassword && !token ? "text" : "password"}
            InputProps={{
              endAdornment: !token && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
              readOnly: !!token,
            }}
            value={password}
            onChange={(ev) => setPassword(ev.target.value)}
            required
            fullWidth
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                console.log("enter press here! ");
                login();
              }
            }}
            style={{
              marginBottom: "1rem",
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& > fieldset": {
                  border: "2px solid rgb(204,204,204)",
                  borderRadius: "8px",
                },
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                "& > fieldset": {
                  border: "2px solid rgb(30, 30, 30)",
                  borderRadius: "8px",
                },
              },
              "& .MuiInputBase-input:-webkit-autofill": {
                boxShadow: "0 0 0 100px #ddf1ff inset",
                MozBoxShadow: "0 0 0 100px #ddf1ff inset",
                WebkitBoxShadow: "0 0 0 100px #ddf1ff inset",
                WebkitTextFillColor: "black",
              },
            }}
          />
          {errorMessage && <p>{errorMessage}</p>}

          {/* {token ? ( */}
          {true ? (
            <Button
              className={isMobile ? "loginButtonMobile" : "loginButton"}
              aria-label="LOG IN"
              disabled={!isFormValid()}
              type="submit"
              onClick={login}
            >
              Login
            </Button>
          ) : (
            <>
              <div className="my-24 flex items-center justify-center">
                <Divider className="w-64" />
              </div>
              <div>
                <Typography
                  className="text-20 sm:text-14"
                  style={{ fontFamily: "Open Sans", color: "gray" }}
                >
                  Check your email, we have sent you a 2FA code.
                </Typography>
              </div>
              <br />

              {/* <form
                  name="twoFactorForm"
                  noValidate
                  className="flex flex-col justify-center w-full"
                  onSubmit={handleTwoFactor}
                > */}
              <TextField
                error={error2fa}
                helperText={error2fa ? "Pin incorrect" : ""}
                autoFocus
                className="mb-16"
                label="Two factor authentication"
                type="text"
                name="twoFactorAuth"
                //   onChange={onHandle2FA}
                onChange={(ev) => setValue2Fa(ev.target.value)}
                value={value2Fa}
                variant="outlined"
                required
                fullWidth
              />
              <Button
                variant="contained"
                background="black"
                className="w-224 mx-auto mt-16"
                aria-label="LOG IN"
                disabled={!isTokenValid()}
                // type="submit"
                //   style={{
                //     backgroundColor: localStorageColorHEX,
                //     color: brightness > 125 ? "black" : "white",
                //   }}
                onClick={login2FA}
              >
                Authenticate
              </Button>
            </>
          )}
        </div>
        <div className={isMobile ? "linksDivMobile" : "linksDiv"}>
          <Typography className="loginText2">@MISSWORLD</Typography>
          <div className="linkButtonsDiv">
            <IconButton
              onClick={() =>
                window.location.replace(
                  "https://www.youtube.com/user/OfficialMissWorld"
                )
              }
            >
              <YouTubeIcon />
            </IconButton>
            <IconButton
              onClick={() =>
                window.location.replace("https://www.facebook.com/MissWorld")
              }
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              onClick={() =>
                window.location.replace("https://twitter.com/MissWorldLtd")
              }
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              onClick={() =>
                window.location.replace("https://www.instagram.com/missworld/")
              }
            >
              <InstagramIcon />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
