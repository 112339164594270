import { useTheme } from "@mui/material";
import { ResponsiveChoropleth } from "@nivo/geo";
import { geoFeatures } from "../data/mockGeoFeatures";
import { tokens } from "../theme";
//import { mockGeographyData as data } from "../data/mockData";
import {getListOfData} from "./dashboardData"
import React, { useEffect, useState } from 'react'

const GeographyChart = ({type="votes", isDashboard = false }) => {
  const theme = useTheme();
  // console.log("type", type)

  const colors = tokens(theme.palette.mode);
  const [countryList, setCountryList] = useState([]);
  // const [votes, setVotes] = useState([]);
  // const [payments, setPayments] = useState([]);
  // const [participant_country_votes, setParticipant_country_votes] = useState([]);
  // const [participant_country_payments, setParticipant_country_payments] = useState([]);
  const [data, setData] = useState([]);
  //const [countryList, setCountryList] = useState([]);
  useEffect(()=>{
    // console.log("type", type)
    getListOfData("countries", setCountryList);

  }, [])

  useEffect(()=>{
    console.log("countryList", countryList,  type);
    let formatted = countryList.map((d) => ({id : d.key, value : d[type] }))
    setData(formatted);
    //setVotes(countryList.map(({data}) => ({id : data.key, value : data["votes"] })));
    // setPayments(countryList.map(({data}) => ({id : data.key, value : data["payments"] })));
    // setParticipant_country_votes(countryList.map(({data}) => ({id : data.key, value : data["participant_country_votes"] })));
    // setParticipant_country_payments(countryList.map(({data}) => ({id : data.key, value : data["participant_country_payments"] })));
  }, [countryList])
  if(data.length > 0) console.log("DATA", data)


  return ( data === undefined?<></> :
    <ResponsiveChoropleth
      data={data}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: "#000000",
            },
          },
          legend: {
            text: {
              fill: "#000000",
            },
          },
          ticks: {
            line: {
              stroke: "#000000",
              strokeWidth: 1,
            },
            text: {
              fill: "#000000",
            },
          },
        },
        legends: {
          text: {
            fill: "#000000",
          },
        },
      }}
      features={geoFeatures.features}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      domain={[0, 100]}
      unknownColor="#666666"
      // label="properties.value"
      valueFormat=".2s"
      projectionScale={isDashboard ? 150 : 200}
      projectionTranslation={isDashboard ? [0.6, 0.8] : [0.6, 0.7]}
      projectionRotation={[0, 0, 0]}
      borderWidth={0.75}
      itemTextColor= {"#000000"}
      borderColor={"#ffffff"}
      legends={
        !isDashboard
          ? [
              {
                anchor: "bottom-left",
                direction: "column",
                justify: true,
                translateX: 10,
                translateY: 35,
                itemsSpacing: -100,
                itemWidth: 80,
                itemHeight: 118,
                itemDirection: "left-to-right",
                itemTextColor: "#ffffff",
                itemOpacity: 1,
                symbolSize: 10,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#ffffff",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
          : undefined
      }
      
    />
  );
};

export default GeographyChart;
