import { Box, Button, Grid, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import PieChart from "../../components/PieChart";
import ProgressCircle from "../../components/ProgressCircle";
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import '../../components/style.css'

import {getTotal} from "../../components/dashboardData";

import en from './dashboard-i18n/en'
import es from './dashboard-i18n/es'
import Topbar from "../global/Topbar";

i18next.addResourceBundle('en', 'dashboard', en)
i18next.addResourceBundle('es', 'dashboard', es)

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation('dashboard')

  const [totalVotes, setTotalVotes] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [totalPayments, setTotalPayments] = useState(0)
  const [totalUsers, setTotalUsers] = useState(0)

  useEffect(()=>{
    getTotal('total_number_of_votes', setTotalVotes)
    getTotal('total_amount_eur', setTotalAmount)
    getTotal('total_number_of_payments_executed',setTotalPayments)
    getTotal('total_users', setTotalUsers)
  }, [])


  return (
    <div style={{height:'auto', backgroundColor:'#353537'}}>
    <Topbar/>
    <Box m="20px" mt="0">
      {/* HEADER */}
      {/* <Box display="flex" justifyContent="end" alignItems="center" marginBottom='9px'>
      </Box> */}
      <Grid container spacing={2}>
          {/* FIRST ROW */}
          <Grid item xs={6} sm={3}>
              <Box
                // gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{height:'100%'}}
              >
                <StatBox
                  title= {totalVotes}
                  subtitle={t('TOTAL_VOTES')}
                />
              </Box>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box
                // gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{height:'100%'}}
              >
                <StatBox
                  title={totalAmount}
                  subtitle={t('AMOUNT_MONEY_COLLECTED')}
                  icon={
                    <PointOfSaleIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box
                // gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{height:'100%'}}
              >
                <StatBox
                  title={totalPayments}
                  subtitle={t('TOTAL_TRANSACTIONS_PROCESSED_SUCCESFULLY')}
                  icon={
                    <PersonAddIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box
                gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{height:'100%'}}
              >
                <StatBox
                  title={totalUsers}
                  subtitle={t('TOTAL_USERS')}
                  icon={
                    <TrafficIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
        </Grid>
          {/* SECOND ROW */}
        <Grid item xs={12} md={6}>
          <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
            style={{height:'100%'}}
          >
            <Typography
              variant="h3"
              fontWeight="600"
              sx={{ padding: "30px 30px 0 30px" }}
            >
              {t('TOTAL_VOTES_BY_MISS')}
            </Typography>
            <Box height="300px" mt="-20px" color="black" 
            // pl="30px"
            >
              <BarChart isDashboard={true} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            gridColumn="span 3"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
            style={{height:'100%'}}
          >
            <Typography
              variant="h3"
              fontWeight="600"
              sx={{ padding: "30px 30px 0 30px" }}
            >
              {t('TOTAL_PAYMENT_BY_PROVIDER')}
            </Typography>
            <Box height="250px" mt="-20px" color="black">
              <PieChart isDashboard={true} type={"PAYMENT_TYPE"}/>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            gridColumn="span 3"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
            style={{height:'100%'}}
          >
            <Typography
              variant="h3"
              fontWeight="600"
              sx={{ padding: "30px 30px 0 30px" }}
            >
              {t('TOTAL_PAYMENT_BY_RESULT')}
            </Typography>
            <Box height="250px" mt="-20px" color="black">
              <PieChart isDashboard={true} type={"PAYMENT_STATUS"}/>
            </Box>
          </Box>
        </Grid>
        {/* THIRD ROW */}
        <Grid item xs={12} sm={6}>
          <Box
            gridColumn="span 6"
            gridRow="span 4"
            backgroundColor={colors.primary[400]}
            padding="30px"
          >
            <Typography
              sx={{ marginBottom: "15px" }}
              variant="h3"
              fontWeight="600"
            >
              {t('COUNTRIES_BY_VOTES')}
            </Typography>
            <Box
              height="50vh"
              border={`1px solid ${colors.grey[100]}`}
              borderRadius="1px"
              color="black"
              >
              <GeographyChart type={"votes"}/>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            gridColumn="span 6"
            gridRow="span 4"
            backgroundColor={colors.primary[400]}
            padding="30px"
          >
            <Typography
              sx={{ marginBottom: "15px" }}
              variant="h3"
              fontWeight="600"
            >
              {t('GEOGRAPHY_MISS')}
            </Typography>
            <Box
              height="50vh"
              border={`1px solid ${colors.grey[100]}`}
              borderRadius="1px"
              color="black"
              >
              <GeographyChart type={"participant_country_votes"}/>
            </Box>
          </Box>
        </Grid>
      </Grid>
      
      {/* </Box> */}
    </Box>
    </div>
  );
};

export default Dashboard;
