const locale = {
  DASHBOARD: "Tablero",
  DASHBOARD_DESCRIPTION: "Bienvenido a su tablero",
  DOWNLOAD_REPORTS: "Descargar Reportes",
  TOTAL_VOTES: "Num. de votos",
  AMOUNT_MONEY_COLLECTED: "Cantidad de dinero",
  AMOUNT_FEE: "Cantidad de comisiones",
  TOTAL_USERS: "Num. de clientes",
  REVENUE_GENERATED: "Revenue generated",
  TOTAL_VOTES_BY_MISS: "Total de votos",
  VOTES_BY_AMOUNT: "Num de votos por precio",
  RECENT_TRANSACTIONS: "Transacciones Recientes",
  TOTAL_EARNED: "Cantidad de Ventas",
  MISS_CZECH_REPUBLIC: "Miss República Checa",
  MISS_SPAIN: "Miss España",
  MISS_ENGLAND: "Miss Inglaterra",
  MISS_BRAZIL: "Miss Brasil",
  MISS_COLOMBIA: "Miss Colombia",
  MISS_TRINIDAD: "Miss Trinidad y Tribago",
  MISS_PARAGUAY: "Miss Paraguay",
  MISS_NEPAL: "Miss Nepal",
};

export default locale;
