import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Loading } from 'notiflix'

const API_URL = 'https://missworldpanel.rewireholding.com/api/metric/total/'

export function getTotal(title, setTotal){
    var config = {
        method: 'get',
        url: `${API_URL}${title}`,
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Basic Z29uemFsb0BzYXVydXMuY29tOnBia2RmMjpzaGEyNTY6MjYwMDAwJEkwWGgzcjhVR2xBUTRoNEMkYWRjYmQ5MTRiNjMxZTM0MmNjN2RhZjU3NWNjZTQ2MDI4OWU4OTI4NGE2NjQyNzlhYTg2NTYyZWRjY2NhYWMxMg=='
        },
       // data : data
      };
    axios(config)
        //.get(`${API_URL}${title}`)
        .then(response => {
             console.log(`Response${title}`, response.data.data);
             setTotal(response.data.data);
            // return response
        })
        .catch(error => console.error(error))
        .finally(() => Loading.remove())
}

export function getListOfData(title, setTotal){
  var config = {
      method: 'get',
      url: `${API_URL}${title}`,
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': 'Basic Z29uemFsb0BzYXVydXMuY29tOnBia2RmMjpzaGEyNTY6MjYwMDAwJEkwWGgzcjhVR2xBUTRoNEMkYWRjYmQ5MTRiNjMxZTM0MmNjN2RhZjU3NWNjZTQ2MDI4OWU4OTI4NGE2NjQyNzlhYTg2NTYyZWRjY2NhYWMxMg=='
      },
     // data : data
    };
  axios(config)
      //.get(`${API_URL}${title}`)
      .then(response => {
          //  console.log(`Response list ${title}`, response.data);
           setTotal(response.data);
          // return response
      })
      .catch(error => console.error(error))
      .finally(() => Loading.remove())
}

