const locale = {
  DASHBOARD: "Dashboard",
  DASHBOARD_DESCRIPTION: "Welcome to your dashboard",
  DOWNLOAD_REPORTS: "Download Reports",
  TOTAL_VOTES: "Total Votes",
  AMOUNT_MONEY_COLLECTED: "Amount of Money Collected",
  NUM_TOTAL_TX: "Total Transactions",
  TOTAL_TRANSACTIONS_PROCESSED_SUCCESFULLY:
    "Total Transactions Processed Succesfully",
  TOTAL_VOTE_BY_PAYMENT_TYPE: "Total votes by payment provider",
  TOTAL_USERS: "Total users",
  TOTAL_VOTES_BY_MISS: "Total votes by Miss",
  VOTES_BY_AMOUNT: "Num of votes by price",
  RECENT_TRANSACTIONS: "Recent Transactions",
  TOTAL_EARNED: "Total earned",
  MISS_CZECH_REPUBLIC: "Miss Czech Republic",
  MISS_SPAIN: "Miss Spain",
  MISS_ENGLAND: "Miss England",
  MISS_BRAZIL: "Miss Brazil",
  MISS_COLOMBIA: "Miss Colombia",
  MISS_TRINIDAD: "Miss Trinidad & Tribago",
  MISS_PARAGUAY: "Miss Paraguay",
  MISS_NEPAL: "Miss Nepal",
  TOTAL_PAYMENT_BY_PROVIDER: "Total payments by provider",
  TOTAL_PAYMENT_BY_RESULT: "Total payments by result",
  GEOGRAPHY_MISS: "Countries by votes received",
  COUNTRIES_BY_VOTES: "Countries by votes",
};

export default locale;
